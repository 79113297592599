
import { Options, Vue } from 'vue-class-component';
import axios from 'axios';
import Spinner from '@/loaders/spinner.vue';

@Options({
  props: {
    msg: String,
    msgSpinner: { type: String },
  },
  components: { Spinner },
  computed: {},
  methods: {
    isLoading() {
      this.isLoading = true;
    },
  },
  data() {
    return {
      isLoading: true,
      setOrgs: [],
    };
  },
  mounted() {
    axios.get('https://api.casjay.vercel.app/api/v1/git/orgs/casjay').then((response) => {
      this.setOrgs = response.data;
      if (!this.setOrgs) {
        this.isLoading = true;
      }
    });
    this.isLoading = false;
  },
})
export default class myOrgs extends Vue {}
